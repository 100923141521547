<template>
  <div>
    <b-container fluid class="p-5">
      <b-row>
        <b-col :md="4">
          <!--          //查找角色-->
          <b-card class="shadow p-3 m-2 mb-4">
            <h5>
              <b-icon icon="search"></b-icon>
              查找角色
            </h5>
            <div>
              <b-form-group
                  label="Search"
                  label-for="filter-input"
                  label-cols-sm="3"
                  label-align-sm="right"
                  label-size="sm"
                  class="mb-3"
              >
                <b-input-group>
                  <b-form-input
                      id="filter-input"
                      v-model="input"
                      type="search"
                      placeholder="Type to Search"
                  ></b-form-input>

                  <b-input-group-append>
                    <b-button variant="primary" @click="searchRoleById">查询</b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>
          </b-card>
          <!--          //创建角色-->
          <b-card class="shadow p-3 m-2 mb-4">
            <h5>
              <b-icon icon="arrow-up-circle"></b-icon>
              创建角色
            </h5>
            <div>
              <b-form-group
                  label="Create"
                  label-for="filter-input"
                  label-cols-sm="3"
                  label-align-sm="right"
                  label-size="sm"
                  class="mb-3"
              >
                <b-input-group>
                  <b-form-input
                      id="filter-input"
                      v-model="addForm"
                      type="search"
                      placeholder="Type to name a new Role"
                  ></b-form-input>

                  <b-input-group-append>
                    <b-button variant="primary" @click="addRole">创建</b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>
          </b-card>
          <!--          //我的角色-->
          <b-card class="shadow p-3 m-2">
            <h5>
              <b-icon icon="person-check"></b-icon>
              我拥有的角色
            </h5>
            <div>
              <b-table striped hover :fields="myRoleFields" :items="myRole"></b-table>
            </div>
          </b-card>

        </b-col>
        <b-col :md="8">
          <!--          //我创建的角色-->
          <b-card class="shadow p-3 m-2">
            <h5>
              <b-icon icon="person-check"></b-icon>
              我创建的角色
              <b-button class="mx-3" variant="outline-primary" @click="goSearchStaff()">分配角色</b-button>
            </h5>
            <div>
              <b-table striped hover :fields="myCreateRoleFields" :items="myCreateRole">
                <template #cell(authorities)="row">
                  <b-button size="sm" @click="row.toggleDetails" class="mr-2">
                    {{ row.detailsShowing ? '收起' : '展开' }}权限
                  </b-button>
                </template>

                <template #row-details="row">
                  <b-button v-b-modal.modal-1 variant="outline-primary" size="sm" class="mr-2"
                            @click="changeEditId(row.item.id)">
                    <b-icon icon="pencil"></b-icon>
                    添加权限
                  </b-button>
                  <el-tag
                      v-for="tag in row.item.authorities"
                      :key="tag.name"
                      closable
                      class="mx-1"
                      @close="handleClose(row.item.id,tag.id)"
                  >
                    {{ tag.name }}
                  </el-tag>
                </template>
                <template #cell(action)="row">
                  <b-button variant="outline-danger" size="sm" @click="removeRole(row.item.id)" class="mx-2">
                    <b-icon icon="x"></b-icon>
                  </b-button>
                </template>
              </b-table>
            </div>
          </b-card>
          <!--          //我授予的角色-->
          <b-card class="shadow m-2 p-3 mt-4">
            <h5>
              <b-icon icon="people"></b-icon>
              我授予的角色
            </h5>
            <el-select class="mt-3 w-75" v-model="selected" placeholder="请选择" @change="showStaffsByRole">
              <el-option
                  v-for="item in myCreateRole"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
            <!-- Main table element -->
            <b-table
                :items="staffList"
                :fields="fields"
                :current-page="1"
                :per-page="20"
            >
              <template #cell(action)="row">
                <b-button variant="outline-danger" size="sm" @click="revokeRole(row.item.id)" class="mr-2">
                  <b-icon icon="x"></b-icon>
                </b-button>
              </template>

            </b-table>

          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <b-modal id="modal-1" title="添加权限">
      <el-select class="mt-3 w-100" multiple v-model="selectedAuthorities" placeholder="请选择">
        <el-option
            v-for="item in authority"
            :key="item.id"
            :label="item.name"
            :value="item.id">
        </el-option>
      </el-select>
      <div style="text-align: center">
        <b-button class="w-50 mx-auto mt-3" @click="addAuthorities">授予权限</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  showMyRoles,
  showRolesOwnedByMe,
  showRoleById,
  createRole,
  showGranteesGrantedByMeByRoleId,
  revokeRole,
  removeRoleById,
  showMyAuthorities,
  grantAuthority,
  addAuthorityToRole,
  removeAuthorityFromRole
} from "@/api";

export default {
  name: "RoleManage",
  data() {
    return {
      editRoleId: 0,
      authority: [],
      selected: '',
      selectedAuthorities: [],
      options: [],
      staffList: [],
      addForm: '',
      input: '',
      myCreateRole: [],
      myRole: [],
      grantedAuthorities: [],
      myRoleFields: [{key: 'id', label: '角色号'}, {key: 'name', label: '角色名'}, {key: 'ownerId', label: '归属者'}],
      fields: [
        {key: 'id', label: '工号',},
        {key: 'name', label: '姓名'},
        {
          key: 'organizationId',
          label: '组织号',
        },
        {key: 'action', label: '操作'}
      ],
      myCreateRoleFields: [{key: 'id', label: '角色号'}, {key: 'name', label: '角色名'}, {
        key: 'authorities',
        label: '权限'
      }, {key: 'action', label: '操作'}],
    }
  },
  created() {
    this.getMyRole()
    this.getRolesOwnedByMe()
    this.getMyAuthority()
  },
  methods: {
    goSearchStaff(){
      this.$router.push({path:'/searchStaff'})
    },
    handleClose(roleId, AuthorityId) {
      this.$confirm('确认移除此权限?').then(() => {
        removeAuthorityFromRole(roleId, AuthorityId).then(res => {
          if (res.data.status) {
            this.$message.success(res.data.message)
            this.getRolesOwnedByMe()
          } else {
            this.$message.error(res.data.message)
          }
        })
      })
    },
    addAuthorities() {
      if (this.selectedAuthorities !== null) {
        let flag = true
        let errorId = []
        this.selectedAuthorities.forEach(s => {
          addAuthorityToRole(this.editRoleId, s).then(res => {
            if (res.data.status === false) {
              this.flag = false
              errorId.push({id: s.id, label: s.name})
            }
          })
        })
        if (flag) {
          this.$message.success('权限添加成功!')
          this.getRolesOwnedByMe()
        } else {
          let errorInfo = ''
          errorId.forEach(e => {
            errorInfo = errorInfo + e.id + ':' + e.label
          })
          this.$message.error(errorInfo + '权限添加失败')
        }
      }
    },
    getMyAuthority() {
      showMyAuthorities().then(res => {
        if (res.data.status) {
          this.authority = res.data.data
        }
      })
    },
    getMyRole() {
      showMyRoles().then(res => {
        if (res.data.status) {
          this.myRole = res.data.data
          this.myRole.forEach(m => {
            this.options.push({value: m.id, text: m.name})
          })
        } else {
          this.$message.error('获取我的角色失败!')
        }
      })
    },
    getRolesOwnedByMe() {
      showRolesOwnedByMe(1, 20).then(res => {
        if (res.data.status) {
          this.myCreateRole = res.data.data
        } else {
          this.$message.error('获取我创建的角色失败')
        }
      })
    },
    searchRoleById() {
      showRoleById(this.input).then(res => {
        if (res.data.status) {
          this.result = res.data.data
        }
      })
    },
    addRole() {
      this.$confirm("是否确认创建角色" + this.addForm, "提示", {
        iconClass: "el-icon-question",//自定义图标样式
        confirmButtonText: "确认",//确认按钮文字更换
        cancelButtonText: "取消",//取消按钮文字更换
        showClose: true,//是否显示右上角关闭按钮
        type: "warning",//提示类型  success/info/warning/error
      }).then(() => {
        createRole(this.addForm).then(res => {
          if (res.data.status) {
            this.getRolesOwnedByMe()
            this.$message.success(res.data.message)
          } else {
            this.$message.error(res.data.message)
          }
        })
      })
    },
    revokeRole(id) {
      revokeRole(this.selected, id).then(res => {
        if (res.data.status) {
          this.$message.success(res.data.message)
          this.showStaffsByRole()
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    showStaffsByRole() {
      showGranteesGrantedByMeByRoleId(this.selected, 1, 50).then(res => {
        if (res.data.status) {
          this.staffList = res.data.data.content
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    changeEditId(id) {
      this.editRoleId = id
    },
    removeRole(id) {
      this.$confirm(
          '是否确认删除此角色?'
      ).then(() => {
        removeRoleById(id).then(res => {
          if (res.data.status) {
            this.$message.success(res.data.message)
            this.getRolesOwnedByMe()
          } else {
            this.$message.error(res.data.message)
          }
        })
      })
    }
  }
}
</script>

<style scoped>

</style>
